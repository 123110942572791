import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/patient-local/patient-record"

export default {
	...commonApi(moduleName),
	getRecordList: data =>
		request("/patient-local/patient-records", {
			params: data
		}),
	addRecord: data =>
		request(`${moduleName}`, {
			method: "post",
			body: data
		}),
	// importRecord: data => request(`${moduleName}/import`, {
	//   method: 'post',
	//   body: data.body,
	// }),
	getPatient: data => request(`/patient-local/patient/${data.id}`, {})
}
