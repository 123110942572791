<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="参加过的项目（附编号）" prop="projectName">
						<Input
							placeholder="请输入参加过的项目（附编号）"
							v-model="formData.projectName"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="筛查号" prop="screenNumber">
						<Input placeholder="请输入筛查号" v-model="formData.screenNumber" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="参加筛查时间" prop="screenTime">
						<Input
							placeholder="请输入参加筛查时间"
							v-model="formData.screenTime"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="末次给药时间" prop="lastMedicTime">
						<Input
							placeholder="请输入末次给药时间"
							v-model="formData.lastMedicTime"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="是否入组" prop="ifIn">
						<Select v-model="formData.ifIn" placeholder="请选择是否入组">
							<Option value="是">是</Option>
							<Option value="否">否</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="出组日期" prop="outTime">
						<Input placeholder="请输入出组日期" v-model="formData.outTime" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="随机号" prop="randomNumber">
						<Input v-model="formData.randomNumber" placeholder="请输入随机号" />
					</FormItem>
				</Col>

				<Col span="24">
					<FormItem label="排除原因" prop="exclusReason">
						<Input
							type="textarea"
							v-model="formData.exclusReason"
							placeholder="请输入排除原因"
						/>
					</FormItem>
				</Col>

				<Col span="24">
					<FormItem label="备注" prop="remark">
						<Input
							type="textarea"
							v-model="formData.remark"
							placeholder="请输入备注"
						/>
					</FormItem>
				</Col>
			</Row>
		</Form>

		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/subject/projexperience"

const { addRecord, apiUpdate } = api

export default {
	name: "Add",
	props: {
		visible: {
			default: false
		},
		patientId: {
			default: ""
		},
		record: {
			default: {}
		},
		records: {
			default: () => []
		}
	},
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rules: {
				projectName: {
					required: true,
					message: "参加过的项目（附编号）不能为空",
					trigger: "blur"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {}
				this.title = "新增项目经历"
				if (this.record.id) {
					this.title = "修改项目经历"
					this.formData = {
						...this.formData,
						...this.record
					}
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					let recordList = this.records
					if (this.record.id) {
						recordList = this.records.filter(item => item.id !== this.record.id)
					}
					const hasSameProName = recordList.find(
						o => o.projectName === this.formData.projectName
					)
					if (hasSameProName) {
						this.$Message.error("不能添加相同的项目！")
					} else {
						this.$asyncDo(async () => {
							this.loading = true
							let res = false
							if (this.record.id) {
								res = await apiUpdate({
									...this.formData,
									patientId: this.patientId,
									id: this.record.id
								})
							} else {
								res = await addRecord({
									...this.formData,
									patientId: this.patientId
								})
							}

							if (res) {
								this.$emit("onOk")
								this.$Message.success("操作成功！")
							}
							this.loading = false
						})
					}
					// if(this.formData.projectName)
				}
			})
			return true
		}
	}
}
</script>

<style scoped></style>
