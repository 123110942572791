<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsAction style="padding-left: 0">
			<Button @click="onOk" :loading="loading">返回</Button>
		</CtmsAction>

		<div
			:style="`{height:${dataGridHeight - 50}px;overflow:auto}`"
			class="viewDetails"
		>
			<div v-if="viewRecords">
				<div class="title">查重数据</div>
				<Form>
					<Row class="patient">
						<Col span="6">
							<FormItem label="姓名">
								{{ viewRecords.name }}
							</FormItem>
						</Col>
						<Col span="4">
							<FormItem label="性别">
								{{ viewRecords.sex }}
							</FormItem>
						</Col>
						<Col span="8">
							<FormItem label="身份证号">
								{{ viewRecords.idCardNo }}
							</FormItem>
						</Col>
						<Col span="6">
							<FormItem label="联系电话">
								{{ viewRecords.phone }}
							</FormItem>
						</Col>
					</Row>
				</Form>

				<Form ref="form">
					<Card class="record">
						<div class="editBtn" v-if="!viewRecords">
							<span @click="updateProRecord(record)">
								<Icon type="md-create" />
							</span>
							<span @click="deleteHandle(record)">
								<Icon type="ios-trash" />
							</span>
						</div>
						<Row :gutter="32" class="margin0">
							<Col span="24">
								<FormItem prop="projectName" label="参加过的项目（附编号）">
									{{ viewRecords.projectName }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="筛查号">
									{{ viewRecords.screenNumber }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="参加筛查时间">
									{{ viewRecords.screenTime }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="末次给药时间">
									{{ viewRecords.lastMedicTime }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="出组时间">
									{{ viewRecords.outTime }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="是否入组">
									{{ viewRecords.ifIn }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="随机号">
									{{ viewRecords.randomNumber }}
								</FormItem>
							</Col>

							<Col span="24">
								<FormItem label="排除原因">
									<p class="reason">{{ viewRecords.exclusReason }}</p>
								</FormItem>
							</Col>

							<Col span="24">
								<FormItem label="备注">
									<p class="remark">{{ viewRecords.remark }}</p>
								</FormItem>
							</Col>
						</Row>
					</Card>
					<div style="text-align: center" v-if="!viewRecords">
						<Button
							@click="addProjectExp"
							class="add"
							icon="md-add"
							type="primary"
							>新增项目经历</Button
						>
					</div>
				</Form>
			</div>
			<div>
				<div class="title" v-if="viewRecords"><span>现有数据</span></div>
				<Form>
					<Row class="patient">
						<Col span="4">
							<FormItem label="姓名">
								{{ patient.name }}
							</FormItem>
						</Col>
						<Col span="4">
							<FormItem label="性别">
								{{ patient.sex }}
							</FormItem>
						</Col>
						<Col span="4">
							<FormItem label="黑名单">
								{{ patient.isBlack ? "是" : "否" }}
							</FormItem>
						</Col>
						<Col span="6">
							<FormItem label="身份证号">
								{{ patient.idCardNo }}
							</FormItem>
						</Col>
						<Col span="6">
							<FormItem label="联系电话">
								{{ patient.phone }}
							</FormItem>
						</Col>
					</Row>
				</Form>

				<Form ref="form">
					<Card v-for="(record, index) in records" :key="index" class="record">
						<div class="editBtn" v-if="!viewRecords && !noEdit">
							<span @click="updateProRecord(record)">
								<Icon type="md-create" />
							</span>
							<span @click="deleteHandle(record)">
								<Icon type="ios-trash" />
							</span>
						</div>
						<Row :gutter="32" class="margin0">
							<Col span="24">
								<FormItem prop="projectName" label="参加过的项目（附编号）">
									<p>{{ record.projectName }}</p>
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="筛查号">
									{{ record.screenNumber }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="参加筛查时间">
									{{ record.screenTime }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="末次给药时间">
									{{ record.lastMedicTime }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="出组时间">
									{{ record.outTime }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="是否入组">
									{{ record.ifIn }}
								</FormItem>
							</Col>
							<Col span="8">
								<FormItem label="随机号">
									{{ record.randomNumber }}
								</FormItem>
							</Col>

							<Col span="24">
								<FormItem label="排除原因">
									<p class="reason">{{ record.exclusReason }}</p>
								</FormItem>
							</Col>

							<Col span="24">
								<FormItem label="备注">
									<p class="remark">{{ record.remark }}</p>
								</FormItem>
							</Col>
						</Row>
					</Card>
					<div style="text-align: center" v-if="!viewRecords && !noEdit">
						<Button
							@click="addProjectExp"
							class="add"
							icon="md-add"
							type="primary"
							>新增项目经历</Button
						>
					</div>
				</Form>
			</div>
		</div>

		<Add
			:visible="addVisible"
			v-show="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:patientId="viewId"
			:record="record"
			:records="records"
		></Add>
	</div>
</template>

<script>
import { mapState } from "vuex"
import api from "@/api/subject/projexperience"
import Add from "./Add.vue"

const { getPatient, apiDelete } = api
export default {
	props: ["visible", "viewId", "viewRecords", "noEdit"],
	data() {
		return {
			loading: false,
			patient: {},
			rules: {
				projectName: {
					required: true,
					message: "项目名称不能为空",
					trigger: "blur"
				}
			},
			addVisible: false,
			records: [],
			record: {}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				if (this.viewId) {
					this.initList()
				}
			}
		}
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const ret = await getPatient({
					id: this.viewId
				})
				if (ret) {
					this.patient = { ...ret.data }
					this.records = [...ret.data.records]
				}
				this.loading = false
			})
		},
		// 新增修改项目经历成功
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		deleteHandle(row) {
			this.$asyncDo(async () => {
				const res = await apiDelete({
					id: row.id
				})
				if (res) {
					this.$Message.success("删除成功")
					this.initList()
				}
			})
		},
		// 新增修改项目经历
		addProjectExp() {
			this.addVisible = true
			this.record = {}
		},
		updateProRecord(obj) {
			this.addVisible = true
			this.record = obj
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$emit("onOk")
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 50
		})
	},
	created() {},
	components: {
		Add
	}
}
</script>

<style lang="less">
.viewDetails {
	.margin0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.record {
		margin-bottom: 15px;
		padding-top: 15px;
		border: dashed 1px #dcdee2;
		.ivu-form-item {
			margin-bottom: 10px;
			p.remark {
				padding-left: 68px;
			}
			p.reason {
				padding-left: 68px;
			}
		}
	}
	.ivu-form .ivu-form-item .ivu-form-item-label {
		color: #333 !important;
		font-weight: 600 !important;
		display: inline-block;
	}
	.patient .ivu-form-item {
		margin-bottom: 12px;
	}

	.add {
		margin-bottom: 15px;
	}

	.editBtn {
		text-align: right;
		font-size: 20px;
		position: absolute;
		right: 10px;
		top: 0px;
		span {
			padding: 5px;
			cursor: pointer;
		}
		button {
			margin-left: 10px;
		}
	}
	.title {
		font-size: 16px;
		font-weight: bold;
		line-height: 1.5;
		color: #333;
		margin-bottom: 10px;
		margin-top: 10px;
	}
}
</style>
